import { ServiceRouteName, serviceRouteNames } from "src/config"

export const getServiceNameById = (id?: string): ServiceRouteName => {
  // BRAKE_PAD_CHANGE and BRAKE_DISC_CHANGE have the same route
  const serviceId = id === "BRAKE_PAD_CHANGE" ? "BRAKE_DISC_CHANGE" : id

  const name = Object.keys(serviceRouteNames).find(
    (key) => serviceRouteNames[key as ServiceRouteName] === serviceId,
  )

  return name as ServiceRouteName
}
