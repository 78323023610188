import { FC } from "react"

import { Button } from "src/components/common/button"
import { Icon } from "src/components/common/icon"

import { ROUTES, serviceFlowSteps } from "src/config"
import { screenBreakpoints } from "src/const/screenBreakpoints"
import { create } from "src/helpers/bem"
import { getPitstopWheelShopData } from "src/helpers/getPitstopWheelShopData"
import { Media } from "src/helpers/media"
import { isHomePage } from "src/helpers/pageUrl"
import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"

import { useGetContentfulServicesData } from "src/hooks/useGetContentfulServicesData"

import styles from "./ServiceCategories.module.scss"
import { ServiceCategoryTile } from "./ServiceCategoryTile"

const bem = create(styles, "ServiceCategories")

export type ServiceCategoriesProps = {
  headline?: string
  buttonLabelDesktop?: string
  buttonLabelMobile?: string
}

/**
 * List of available service categories, fetched from Contentful
 */
export const ServiceCategories: FC<ServiceCategoriesProps> = ({
  headline,
  buttonLabelDesktop,
  buttonLabelMobile,
}) => {
  const { categoriesData } = useGetContentfulServicesData()

  const onShowAllServicesClick = () => {
    const homePage = isHomePage()
    if (homePage) {
      sendTrackingEvent.home({
        gaEvent: { action: "show_all_services" },
      })
    } else {
      sendTrackingEvent.services({
        gaEvent: { action: "show_all_services_tile_click" },
      })
    }
  }
  const { wheelStorageCategory, pitstopWheelShopUrl } =
    getPitstopWheelShopData()

  return (
    <div className={bem()}>
      <h2 className={bem("title")}>{headline}</h2>

      <ul className={bem("list")}>
        {categoriesData?.slice(0, 6).map((serviceCategory, index) => (
          <li
            key={serviceCategory.internalServiceCategoryId}
            className={bem("item", {
              lg: index > 3,
              md: index > 2,
            })}
          >
            <ServiceCategoryTile serviceCategory={serviceCategory} />
          </li>
        ))}

        <li>
          <ServiceCategoryTile
            serviceCategory={wheelStorageCategory}
            externalUrl={pitstopWheelShopUrl}
          />
        </li>

        <Button
          variant="primary"
          href={ROUTES.serviceFlow({ flowStep: serviceFlowSteps.service })}
          className={bem("button")}
          onClick={onShowAllServicesClick}
        >
          <div className={bem("link")}>
            <Media minWidth={screenBreakpoints.md}>
              <Icon name="navArrowRight" className={bem("icon")} />
              <span className={bem("label")}>{buttonLabelDesktop}</span>
            </Media>
            <Media maxWidth={screenBreakpoints.md}>
              <span className={bem("label")}>{buttonLabelMobile}</span>
            </Media>
          </div>
        </Button>
      </ul>
    </div>
  )
}
