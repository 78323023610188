import { Category } from "src/helpers/autoservice-core-backend/types"

export const getPitstopWheelShopData = () => {
  const baseUrl = "https://www.pitstop.de/Reifen/Suche"

  const params = {
    utm_source: "huk-autoservice.de",
    utm_medium: "web",
    utm_campaign: "HAG-wheel-campaign",
  }

  const url = new URL(baseUrl)

  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.append(key, value)
  })

  const wheelStorageCategory: Category = {
    additionalServices: [],
    icon: "wheelStorage",
    title: "Reifenshop",
    services: [],
  }

  return { pitstopWheelShopUrl: url.href, wheelStorageCategory }
}
