import { FC } from "react"

import { BaseLink } from "src/components/common/base-link"
import { Icon } from "src/components/common/icon"
import { useCategoryName } from "src/components/pages/services/flow/hooks/useCategoryName"
import { ROUTES } from "src/config"
import { Category } from "src/helpers/autoservice-core-backend/types"
import { create } from "src/helpers/bem"
import { getServiceNameById } from "src/helpers/getServiceNameById"
import { isHomePage } from "src/helpers/pageUrl"
import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"

import styles from "./ServiceCategoryTile.module.scss"

const bem = create(styles, "ServiceCategoryTile")

type ServiceCategoryTileProps = {
  serviceCategory: Category
  externalUrl?: string
}

/**
 * A tile of the [Service Categories](../?path=/docs/cck-sections-service-categories--docs) component
 */
export const ServiceCategoryTile: FC<ServiceCategoryTileProps> = ({
  serviceCategory,
  externalUrl,
}) => {
  const { title, internalServiceCategoryId, icon } = serviceCategory

  const categoryName = useCategoryName(internalServiceCategoryId, title)

  const serviceName = getServiceNameById(internalServiceCategoryId)

  const onServiceClick = () => {
    const homePage = isHomePage()
    if (homePage) {
      sendTrackingEvent.home({
        gaEvent: { action: "select_service_button", label: title },
      })
    } else {
      sendTrackingEvent.services({
        gaEvent: { action: "select_service_button_service_tile", label: title },
      })
    }
  }

  const href = externalUrl ?? ROUTES.service({ serviceName })

  return (
    <BaseLink onClick={onServiceClick} className={bem()} href={href}>
      {icon && <Icon name={icon} className={bem("icon")} />}
      <span className={bem("label")}>
        {categoryName}
        {!!externalUrl && (
          <Icon name={"externalLink"} className={bem("icon-link")} />
        )}
      </span>
    </BaseLink>
  )
}
