import { useTranslation } from "src/hooks/translation"

export const useCategoryName = (
  categoryId?: string,
  categoryOriginalName?: string,
) => {
  const { messages } = useTranslation()
  const translations = messages.pages.services.flow.service.categoryCard

  if (!categoryId) {
    return categoryOriginalName
  }

  const categoryNameMapper: { [key: string]: string } = {
    HU_AU: translations.huau.title,
    BRAKE_PAD_CHANGE: translations.brakesChange.categoryTitle,
    DIAGNOSE: translations.diagnose.title,
  }

  const categoryName = categoryNameMapper[categoryId] ?? categoryOriginalName

  return categoryName
}
