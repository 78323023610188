import useSWR from "swr/immutable"

import { ROUTES } from "src/config"
import { Category } from "src/helpers/autoservice-core-backend/types"
import { performRequest } from "src/helpers/http/http"
import { HttpError } from "src/helpers/http/HttpError"

export type UseGetContentfulServicesDataHook = () => {
  categoriesData: Category[] | undefined
  isLoading: boolean
  error: HttpError
}

async function getContentfulServices(): Promise<Category[]> {
  return performRequest({
    method: "POST",
    path: ROUTES.api.core.services.getContentfulServices,
  })
}

export const useGetContentfulServicesData: UseGetContentfulServicesDataHook =
  () => {
    const { data, error, isLoading } = useSWR({}, getContentfulServices)

    return {
      categoriesData: data,
      isLoading,
      error,
    }
  }
