import Router from "next/router"

import { ROUTES, servicesBookingConfirmation } from "src/config"

export function isHomePage() {
  return Router.pathname === ROUTES.index
}

export function isSuccessPage() {
  return Router.pathname?.includes(servicesBookingConfirmation)
}
